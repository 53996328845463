.mce-edit-focus {
  cursor: text;
  outline: none !important ;
  border: none !important;
  &:focus-visible {
    outline: none !important ;
    border: none !important;
  }
}
.tox-tinymce {
  border: 1px solid #e8e8e8 !important;
  border-radius: 0.5% !important;
}
.tox.tox-tinymce-inline .tox-editor-header {
  border-radius: 0 !important;
  border: none !important;
}
.tox-dialog {
  z-index: 25; /* Adjust this value as needed */
}
.tox .tox-menu.tox-collection.tox-collection--list.tox-selected-menu {
  z-index: 20 !important;
}
.tox-tinymce-aux {
  z-index: 100 !important;
}

.tox-toolbar__primary {
  background-color: #f9f9f9 !important;
}
.mce-content-body {
  height: 100%;
}
