.react-datepicker__close-icon {
  position: absolute;
  right: 6px;
  top: 1.2rem;
  transform: translateY(-50%);
  cursor: pointer;
}

.react-datepicker__close-icon::after {
  background-color: transparent; /* Adjust the color if needed */
  border-radius: 50%;
  color: #0000008a;
  font-size: 26px;
  padding-left: 2px;
  right: 2px;
}

.highlight {
  background-color: yellow;
  font-weight: bold;
}

@keyframes fadePulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-6px);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

